import { render, staticRenderFns } from "./StoreMarketingSucceed.vue?vue&type=template&id=25a89c27&scoped=true&"
import script from "./StoreMarketingSucceed.vue?vue&type=script&lang=js&"
export * from "./StoreMarketingSucceed.vue?vue&type=script&lang=js&"
import style0 from "./StoreMarketingSucceed.vue?vue&type=style&index=0&id=25a89c27&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a89c27",
  null
  
)

export default component.exports